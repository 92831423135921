var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "modal-edit-form-item",
    attrs: {
      id: "modal-edit-form-item",
      "footer-class": "flex-nowrap",
      "no-close-on-backdrop": "",
      centered: ""
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function() {
          return [
            _c("h3", [_vm._v(_vm._s(_vm.$t("modals.editFormItem.title")))])
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("modal-choose-team-name-and-description", {
              attrs: {
                teamName: _vm.formItemName,
                teamDescription: _vm.formItemDescription
              },
              on: {
                "update-fields": _vm.updateFields,
                "enter-pressed": _vm.editFormItem
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function({ close }) {
          return [
            _c(
              "b-button",
              {
                staticClass: "p-3 border-right-light-grey",
                attrs: { block: "" },
                on: {
                  click: function($event) {
                    return close()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
            ),
            _c(
              "b-button",
              {
                staticClass: "p-3",
                attrs: {
                  disabled: !_vm.isFormItemNameSet,
                  variant: "primary",
                  block: ""
                },
                on: { click: _vm.editFormItem }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("modals.editFormItem.validate")) + " "
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }